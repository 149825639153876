import React, { useState } from "react";
import Layout from "../../layout";
import api from "../../services/api";
import isEmail from "validator/lib/isEmail";

const devValue = (value, falseValue = "") => {
  return process.env.NODE_ENV === "development" ? value : falseValue;
};

// markup
const PasswordResetPage = () => {
  const [progress, setProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("success");

  const [email, setEmail] = useState(devValue("mahesh@owita.lk"));

  const handleSubmit = (e) => {
    e.preventDefault();
    setProgress(true);
    setMessage(false);
    try {
      api("post", "reset-password", { email }).then((res) => {
        setProgress(false);
        if (res.data.status && res.data.message) {
          setType("success");
          setMessage("Please check your email for the instructions.");
        }
      });
    } catch (error) {
      setProgress(false);
      setType("danger");
      setMessage(
        "Error occurred while login. If this error persists, please contact system administrator."
      );
    }
  };

  return (
    <Layout
      title={"Password Reset"}
      description={"Forgot your password? Enter your email to reset it."}
      publicPage
    >
      <section className="login py-8 py-lg-16">
        <article className="form-wrapper ms-auto me-auto">
          <form
            action=""
            method="post"
            onSubmit={handleSubmit}
            className={"position-relative"}
          >
            {message && (
              <div
                className="position-absolute w-100"
                style={{ bottom: "100%" }}
              >
                <div className={`alert alert-${type} alert-icon`} role="alert">
                  <i
                    className={`uil ${
                      type === "danger"
                        ? "uil-times-circle"
                        : "uil-check-circle"
                    }`}
                  />{" "}
                  {message}
                </div>
              </div>
            )}

            <div className="mb-4">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <input
              type="submit"
              disabled={!isEmail(email) || progress}
              value="Send Password Reset Link"
              className="btn btn-primary rounded-pill mb-4 ms-auto me-auto text-center d-block w-100"
            />
          </form>
        </article>
      </section>
    </Layout>
  );
};

export default PasswordResetPage;
